// import * as React from "react"
// import { Link, graphql } from "gatsby"

// import Layout from "../components/layout"
// import Seo from "../components/seo"
// import { Helmet } from "react-helmet"

// const BlogIndex = ({ data, location }) => {
//   const siteTitle = data.site.siteMetadata?.title || `Title`
//   const posts = data.allMarkdownRemark.nodes

//   if (posts.length === 0) {
//     return (
//       <Layout location={location} title={siteTitle}>
//         <p>
//           No blog posts found. Add markdown posts to "content/blog" (or the
//           directory you specified for the "gatsby-source-filesystem" plugin in
//           gatsby-config.js).
//         </p>
//       </Layout>
//     )
//   }

//   return (
//     <Layout location={location} title={siteTitle}>
//       <Helmet>
//         <link rel="canonical" href="https://www.collectivetops.com/blog" />
//         <title>Blog | Collective Tops</title>
//         <meta name="description" content='Explore the freshest dress trends on our blog, highlighting chic midi, playful mini, elegant maxi, and breath-taking formal wear. Gain inspiration for outfits, receive expert styling advice, and navigate fashion for every event. Amplify your style with our varied dress collection.'/>      
//       </Helmet>
//       <div
//         className="blog-list-container grid grid-cols-2 gap-4 mt-8"
//         style={{ listStyle: `none` }}
//       >
//         {posts.map(post => {
//           const title = post.frontmatter.title || post.fields.slug

//           return (
//             <div className="blog-list" key={post.fields.slug}>
//               <article
//                 className="post-list-item"
//                 itemScope
//                 itemType="http://schema.org/Article"
//               >
//                 <header>
//                   <h2>
//                     <Link
//                       className="text-lg font-bold no-underline text-black hover:underline"
//                       to={post.fields.slug}
//                       itemProp="url"
//                     >
//                       <img
//                         className="max-w-sm mx-5 hover:opacity-80"
//                         src={post.frontmatter.frontimage}
//                       />
//                       <span itemProp="headline">{title}</span>
//                     </Link>
//                   </h2>
//                   <small>{post.frontmatter.date}</small>
//                 </header>
//                 <section>
//                   <p
//                     className="max-w-xl"
//                     dangerouslySetInnerHTML={{
//                       __html: post.frontmatter.description || post.excerpt,
//                     }}
//                     itemProp="description"
//                   />
//                 </section>
//               </article>
//             </div>
//           )
//         })}
//       </div>
//     </Layout>
//   )
// }

// export default BlogIndex

// /**
//  * Head export to define metadata for the page
//  *
//  * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
//  */
// export const Head = () => <Seo title="All posts" />

// export const pageQuery = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//     allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
//       nodes {
//         excerpt
//         fields {
//           slug
//         }
//         frontmatter {
//           date(formatString: "MMMM DD, YYYY")
//           title
//           description
//           frontimage
//         }
//       }
//     }
//   }
// `





import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import RecommendationHome from "../components/recommendation-home"

const Index = ({ data, location }) => {

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array
  }

  const siteTitle = data.site.siteMetadata?.title || `Title`
  
  const topsAll = shuffleArray(data.topsAll.edges)
  const topsCrops = shuffleArray(data.topsCrops.edges)
  const topsCorset = shuffleArray(data.topsCorset.edges)
  const topsShort = shuffleArray(data.topsShort.edges)
  const topsLong = shuffleArray(data.topsLong.edges)
  const topsSleeveless = shuffleArray(data.topsSleeveless.edges)
  const topsBodySuits = shuffleArray(data.topsBodySuits.edges)
  

  return (
    <Layout location={location} title={siteTitle}>
      <Helmet>
        <title>Collective Tops - Newest and hottest tops - your ultimate tops destination.  </title>
        <link rel="canonical" href="https://www.collectivetops.com/" />      
        <meta name="description" content="At Collective Tops, we pride ourselves on offering the largest and most superior selection of women's tops available. Our expansive inventory guarantees a variety of styles to suit every personal preference. In our dedication to staying current and fashion-forward, we ensure that new styles are added to our collections on a weekly basis, providing a refreshing and updated selection consistently."/>
  
  <script type="application/ld+json">
          {`
            {
              "@context": "http://www.schema.org",
              "@type": "Organization",
              "name": "Collective Tops",
              "url": "https://www.collectivetops.com",
              "logo": "https://www.collectivetops.com/static/4dcefb04f813c8b4dfc1c8dfe427f5e8/8acde/logo-crop.avif",
              "image": "https://www.collectivetops.com/static/4dcefb04f813c8b4dfc1c8dfe427f5e8/8acde/logo-crop.avif",
              "description": "At Collective Tops, we pride ourselves on offering the largest and most superior selection of women's tops available. Our expansive inventory guarantees a variety of styles to suit every personal preference. In our dedication to staying current and fashion-forward, we ensure that new styles are added to our collections on a weekly basis, providing a refreshing and updated selection consistently."
            }
        `}
        </script>
      </Helmet>
      <div>

      <RecommendationHome data={topsAll} name="New" collection="new-tops"/>
      <RecommendationHome data={topsCrops} name="Crop"  collection="tops-crop"/>
      <RecommendationHome data={topsCorset} name="Corset" collection="tops-corset"/>
      <RecommendationHome data={topsShort} name="Short" collection="tops-short"/>
      <RecommendationHome data={topsLong} name="Long" collection="tops-long"/>
      <RecommendationHome data={topsSleeveless} name="Sleeveless" collection="tops-sleeveless"/>
     <RecommendationHome data={topsBodySuits} name="Bodysuits"  collection="bodysuits"/>
      </div>
      <h1 class="home-intro">At Collective Tops, we pride ourselves on offering the largest and most superior selection of women's tops available. Our expansive inventory guarantees a variety of styles to suit every personal preference. In our dedication to staying current and fashion-forward, we ensure that new styles are added to our collections on a weekly basis, providing a refreshing and updated selection consistently.</h1>
      <h2 class="home-intro">We stand as a central hub for trendy and chic women's tops. By aggregating the latest and most popular designs from a variety of sources, we streamline your shopping experience, ensuring you have access to a vast variety of styles all in one place. This allows you to save the immense amounts of time ordinarily spent on browsing multiple stores and boutiques in search of the perfect garment.
</h2>
<h2 class="home-intro">Collective Tops is your one-stop solution for trendy, fashionable women's tops. We simplify your shopping experience, providing an array of fashionable items to choose from. You can rest assured knowing that you're always viewing the trendiest items when shopping with us.
</h2>
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    topsAll: allAirtable(limit: 50, filter: { table: { eq: "tops_all" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }
    topsCrops: allAirtable(limit: 50, filter: { table: { eq: "tops_crops" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }
    topsCorset: allAirtable(limit: 50, filter: { table: { eq: "tops_corset" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }        
    topsShort: allAirtable(limit: 50, filter: { table: { eq: "tops_short" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }
    topsLong: allAirtable(limit: 50, filter: { table: { eq: "tops_long" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }
    topsSleeveless: allAirtable(limit: 50, filter: { table: { eq: "tops_sleeveless" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }
    topsBodySuits: allAirtable(limit: 50, filter: { table: { eq: "tops_bodysuits" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }    
  }
`